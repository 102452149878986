import React, { useState, useEffect } from 'react';
import ListComponent from './ListComponent';
import ModalBase from './ModalBase';
import ProjectForm from './ProjectForm';
import PostManagement from './PostManagement';
import api from '../axiosConfig';
import Swal from 'sweetalert2';

const PainelProjetos = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editProject, setEditProject] = useState(null);
  const [showPostManagement, setShowPostManagement] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await api.get('/projects');
        setProjects(response.data);
      } catch (error) {
        console.error("Erro ao carregar projetos:", error);
        Swal.fire('Erro!', 'Ocorreu um erro ao carregar os projetos.', 'error');
      }
    };

    const fetchClients = async () => {
      try {
        const response = await api.get('/clients');
        setClients(response.data);
      } catch (error) {
        console.error("Erro ao carregar clientes:", error);
        Swal.fire('Erro!', 'Ocorreu um erro ao carregar os clientes.', 'error');
      }
    };

    fetchProjects();
    fetchClients();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const getClientNameById = (clientId) => {
    const client = clients.find(client => client._id === clientId);
    return client ? client.nome : 'Cliente Desconhecido';
  };

  const filteredProjects = projects.map(project => ({
    ...project,
    clientName: getClientNameById(project.client),
    formattedDate: new Date(project.createdAt).toLocaleDateString('pt-BR')
  })).filter(project =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    project.clientName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEdit = (project) => {
    setEditProject(project);
    setShowModal(true);
  };

  const handleDelete = async (project) => {
    const result = await Swal.fire({
      title: 'Tem certeza?',
      text: `Você realmente deseja remover o projeto ${project.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, remover!',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      try {
        await api.delete(`/projects/${project._id}`);
        setProjects(projects.filter(p => p._id !== project._id));
        Swal.fire('Removido!', `O projeto ${project.name} foi removido.`, 'success');
      } catch (error) {
        console.error("Erro ao deletar projeto:", error);
        Swal.fire('Erro!', 'Ocorreu um erro ao remover o projeto.', 'error');
      }
    }
  };

  const handleAddProject = async (projectData) => {
    try {
      if (editProject) {
        await api.put(`/projects/${editProject._id}`, projectData);
        setProjects(projects.map(p => (p._id === editProject._id ? { ...projectData, _id: editProject._id } : p)));
        Swal.fire('Editado!', 'O projeto foi editado com sucesso.', 'success');
        setEditProject(null);
      } else {
        const response = await api.post('/projects', projectData);
        setProjects([...projects, response.data]);
        Swal.fire('Adicionado!', 'O projeto foi adicionado com sucesso.', 'success');
      }
      setShowModal(false);
    } catch (error) {
      console.error("Erro ao adicionar/editar projeto:", error);
      Swal.fire('Erro!', 'Ocorreu um erro ao adicionar/editar o projeto.', 'error');
    }
  };

  const handleAddClient = async (newClient) => {
    try {
      const response = await api.post('/clients', newClient);
      const createdClient = response.data;
      setClients([...clients, createdClient]);
      return createdClient;
    } catch (error) {
      console.error('Erro ao adicionar cliente:', error);
      Swal.fire('Erro!', 'Ocorreu um erro ao adicionar o cliente.', 'error');
      return null;
    }
  };

  const handleManagePosts = (project) => {
    setEditProject(project);
    setShowPostManagement(true);
  };

  const handleBackToProjects = () => {
    setShowPostManagement(false);
    setEditProject(null);
  };

  return (
    <div className="p-8 min-h-screen bg-base">
      {!showPostManagement ? (
        <>
          <div className="mb-4 flex justify-between items-center">
            <input
              type="text"
              placeholder="Buscar por nome, cliente..."
              value={searchTerm}
              onChange={handleSearch}
              className="border border-base p-2 flex-grow mr-4 rounded focus:ring-base"
            />
            <button
              className="border-2 border-white text-white bg-base font-bold py-2 px-4 rounded hover:bg-white hover:text-base transition duration-200 ease-in-out"
              onClick={() => setShowModal(true)}
            >
              Adicionar Projeto
            </button>
          </div>

          <ListComponent
            items={filteredProjects}
            title="Lista de Projetos"
            primaryKey="name"
            secondaryKey="clientName"
            tertiaryKey="formattedDate"
            onEdit={handleEdit}
            onDelete={handleDelete}
            onManage={handleManagePosts}
            showManage={true}
            showToggle={false}
            showDate={true}
          />
        </>
      ) : (
        <PostManagement project={editProject} onBack={handleBackToProjects} />
      )}

      {showModal && (
        <ModalBase title={editProject ? "Editar Projeto" : "Adicionar Novo Projeto"} onClose={() => { setShowModal(false); setEditProject(null); }}>
          <ProjectForm
            onSubmit={handleAddProject}
            onAddClient={handleAddClient}
            onCancel={() => setShowModal(false)}
            project={editProject}
            existingClients={clients}
          />
        </ModalBase>
      )}
    </div>
  );
};

export default PainelProjetos;
