// src/axiosConfig.js
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

// Defina a URL base a partir da variável de ambiente
//const API_BASE_URL = 'http://localhost:3001/api';
const API_BASE_URL = 'https://api.agah.arq.br/api';

// Crie uma função para retornar a configuração correta do axios
const createApiInstance = () => {
  const token = localStorage.getItem('authToken');

  return axios.create({
    baseURL: API_BASE_URL,
    headers: token ? { Authorization: `Bearer ${token}` } : {}, // Inclui o cabeçalho de autorização apenas se o token existir
    withCredentials: !!token, // Define `withCredentials` como `true` apenas se o token existir
  });
};

// Configurando o interceptor do Axios
const api = createApiInstance();

api.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config;

      // Verifica se a requisição foi uma tentativa de login
      const isAuthRequest = originalRequest.url.includes('/authenticate');

      if (!isAuthRequest) {
        // Se o token estiver expirado ou inválido (e não for uma tentativa de login), exiba o popup
        const result = await MySwal.fire({
          title: 'Sessão expirada',
          text: 'Sua sessão expirou, por favor, faça login novamente.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Login',
          allowOutsideClick: false
        });

        if (result.isConfirmed) {
          // Redirecionar ou abrir o modal de login
          window.location.href = '/painel'; // Ou exibir um modal de login
        }
      }
    }
    return Promise.reject(error);
  }
);

export default api;
