import React, { useState, useEffect } from 'react';
import ListComponent from './ListComponent';
import api from '../axiosConfig';
import ModalBase from './ModalBase';
import UserForm from './UserForm';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const PainelUsuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editUser, setEditUser] = useState(null);

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const response = await api.get('/users');
        setUsuarios(response.data);
      } catch (error) {
        console.error("Erro ao carregar usuários:", error);
        MySwal.fire('Erro!', 'Ocorreu um erro ao carregar os usuários.', 'error');
      }
    };

    fetchUsuarios();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEdit = (usuario) => {
    setEditUser(usuario);
    setShowModal(true);
  };

  const handleDelete = async (usuario) => {
    const result = await MySwal.fire({
      title: 'Tem certeza?',
      text: `Você realmente deseja remover o usuário "${usuario.nome}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, remover!',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      try {
        await api.delete(`/users/${usuario._id}`);
        setUsuarios(usuarios.filter(u => u._id !== usuario._id));
        MySwal.fire('Removido!', 'O usuário foi removido com sucesso.', 'success');
      } catch (error) {
        console.error("Erro ao remover usuário:", error);
        MySwal.fire('Erro!', 'Ocorreu um erro ao remover o usuário.', 'error');
      }
    }
  };

  const handleInactivate = async (usuario) => {
    try {
      await api.put(`/users/${usuario._id}/toggle-active`, null);
      setUsuarios(usuarios.map(u => (u._id === usuario._id ? { ...u, ativo: !u.ativo } : u)));
    } catch (error) {
      console.error("Erro ao inativar/ativar usuário:", error);
    }
  };

  const handleAddUser = async (userData) => {
    try {
      if (editUser) {
        // Se estiver editando, envia uma requisição PUT
        await api.put(`/users/${editUser._id}`, userData);
        setUsuarios(usuarios.map(u => (u._id === editUser._id ? { ...userData, _id: editUser._id } : u)));
        MySwal.fire('Editado!', 'O usuário foi editado com sucesso.', 'success');
        setEditUser(null); // Reseta o estado de edição
      } else {
        // Se estiver adicionando, envia uma requisição POST
        const response = await api.post('/users', userData);
        setUsuarios([...usuarios, response.data]);
        MySwal.fire('Adicionado!', 'O usuário foi adicionado com sucesso.', 'success');
      }
      setShowModal(false);
    } catch (error) {
      console.error("Erro ao adicionar/editar usuário:", error);
      MySwal.fire('Erro!', 'Ocorreu um erro ao adicionar/editar o usuário.', 'error');
    }
  };

  const handleResetPassword = async (userId) => {
    try {
      await api.put(`/users/${userId}/reset-password`, null);
      MySwal.fire('Senha resetada', 'A senha foi resetada.', 'success');
    } catch (error) {
      console.error('Erro ao resetar a senha:', error);
      MySwal.fire('Erro!', 'Ocorreu um erro ao resetar a senha.', 'error');
    }
  };

  const filteredUsuarios = usuarios.filter(usuario =>
    usuario.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
    usuario.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    usuario.tipo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-8">
      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Pesquisar usuários..."
          value={searchTerm}
          onChange={handleSearch}
          className="border rounded p-2 flex-grow mr-4"
        />
        <button
          className="border-2 border-white text-white bg-base font-bold py-2 px-4 rounded hover:bg-white hover:text-base transition duration-200 ease-in-out"
          onClick={() => setShowModal(true)}
        >
          Adicionar Usuário
        </button>
      </div>
      <ListComponent
        items={filteredUsuarios}
        title="Usuários"
        primaryKey="nome"
        secondaryKey="email"
        tertiaryKey="tipo"
        onEdit={handleEdit}
        onDelete={handleDelete}
        onToggle={handleInactivate}
      />
      {showModal && (
        <ModalBase title={editUser ? "Editar Usuário" : "Adicionar Novo Usuário"} onClose={() => { setShowModal(false); setEditUser(null); }}>
          <UserForm onSubmit={handleAddUser} onResetPassword={handleResetPassword} onCancel={() => { setShowModal(false); setEditUser(null); }} user={editUser} />
        </ModalBase>
      )}
    </div>
  );
};

export default PainelUsuarios;
