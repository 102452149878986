import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faFolder, faFile, faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

const ListComponentArmazenamento = ({
  items,
  title,
  onDelete,
  onNavigate,
  onBack,
  showBackButton,
  onDownload // Nova prop para o download do arquivo
}) => {
  // Função para confirmar a exclusão de um arquivo ou diretório
  const confirmDelete = (item) => {
    Swal.fire({
      title: 'Tem certeza?',
      text: `Você está prestes a deletar ${item.nome}. Esta ação não pode ser desfeita!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(item); // Executa a função de deletar após confirmação
        Swal.fire(
          'Deletado!',
          `${item.nome} foi deletado com sucesso.`,
          'success'
        );
      }
    });
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg h-full">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-bold">{title}</h3>
        {showBackButton && (
          <div
            onClick={onBack}
            className="text-base text-blue-500 hover:underline cursor-pointer"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
            Voltar
          </div>
        )}
      </div>

      <ul className="space-y-2">
        {items.map((item, index) => (
          <li
            key={index}
            className={`flex justify-between items-center border-b pb-2 hover:bg-gray-100 cursor-pointer ${
              item.isDirectory ? "hover:text-blue-500" : ""
            }`}
            onClick={() => item.isDirectory && onNavigate(item.nome)} // Agora a linha inteira é clicável
          >
            <div className="flex items-center">
              <FontAwesomeIcon
                icon={item.isDirectory ? faFolder : faFile}
                className="mr-2"
              />
              <div className="font-semibold">{item.nome}</div>
            </div>
            <div className="flex items-center space-x-2">
              {!item.isDirectory && (
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Evita que o clique no botão acione o clique na linha
                    onDownload(item); // Chama a função de download para o arquivo
                  }}
                  className="text-blue-500 hover:text-blue-700"
                  title="Download"
                >
                  <FontAwesomeIcon icon={faDownload} />
                </button>
              )}
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Evita que o clique no botão delete acione o clique na linha
                  confirmDelete(item); // Chama o SweetAlert para confirmar a exclusão
                }}
                className="text-red-500 hover:text-red-700"
                title="Remover"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListComponentArmazenamento;
