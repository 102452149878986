import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import api from '../axiosConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ setActivePage }) => {
  const [activeItem, setActiveItem] = useState('inicio');
  const [isVisible, setIsVisible] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem('user'));
        const userId = userData.user._id;
        const token = userData.token;

        const response = await api.get(`/users/${userId}`);

        setUser(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
      }
    };

    fetchUserData();

    setTimeout(() => {
      setIsVisible(true);
    }, 200);
  }, []);

  const handleItemClick = (item) => {
    if (item !== activeItem) {
      setActiveItem(item);
      setActivePage(item);
    }
  };

  const handleLogout = () => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: "Você será redirecionado para a tela de login.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, sair!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
        navigate('/painel', { replace: true });

        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function() {
          window.history.go(1);
        };
      }
    });
  };

  return (
    <div
      className={`transform transition-transform duration-[1500ms] ${
        isVisible ? 'translate-x-0' : '-translate-x-full'
      } w-1/6 p-8 flex flex-col justify-between bg-[#f1e8dd] h-full`}
    >
      <div>
        <h2 className="text-4xl font-bold mb-8">Menu</h2>
        <ul className="space-y-4">
          <li>
            <button
              onClick={() => handleItemClick('inicio')}
              className={`w-full text-left py-2 px-4 rounded ${
                activeItem === 'inicio'
                  ? 'bg-base text-white'
                  : 'bg-white text-gray-700 hover:bg-[#D2A67D] hover:text-white'
              } transition duration-300`}
            >
              Início
            </button>
          </li>
          <li>
            <button
              onClick={() => handleItemClick('projetos')}
              className={`w-full text-left py-2 px-4 rounded ${
                activeItem === 'projetos'
                  ? 'bg-base text-white'
                  : 'bg-white text-gray-700 hover:bg-[#D2A67D] hover:text-white'
              } transition duration-300`}
            >
              Projetos
            </button>
          </li>
          <li>
            <button
              onClick={() => handleItemClick('clientes')}
              className={`w-full text-left py-2 px-4 rounded ${
                activeItem === 'clientes'
                  ? 'bg-base text-white'
                  : 'bg-white text-gray-700 hover:bg-[#D2A67D] hover:text-white'
              } transition duration-300`}
            >
              Clientes
            </button>
          </li>
          {user?.tipo === 'administrador' && (
            <li>
              <button
                onClick={() => handleItemClick('usuarios')}
                className={`w-full text-left py-2 px-4 rounded ${
                  activeItem === 'usuarios'
                    ? 'bg-base text-white'
                    : 'bg-white text-gray-700 hover:bg-[#D2A67D] hover:text-white'
                } transition duration-300`}
              >
                Usuários
              </button>
            </li>
          )}
          <li>
            <button
              onClick={() => handleItemClick('armazenamento')}
              className={`w-full text-left py-2 px-4 rounded ${
                activeItem === 'armazenamento'
                  ? 'bg-base text-white'
                  : 'bg-white text-gray-700 hover:bg-[#D2A67D] hover:text-white'
              } transition duration-300`}
            >
              Armazenamento
            </button>
          </li>
        </ul>
      </div>

      <div className="mt-auto flex flex-col items-center">
        <FontAwesomeIcon icon={faUser} className="w-16 h-16 text-gray-700 mb-2" />
        <p className="text-gray-700 text-lg">{user?.nome || 'Nome do Usuário'}</p>
        <button 
          className="mt-4 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-300"
          onClick={handleLogout}
        >
          Sair
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
