import React, { useState, useEffect } from 'react';
import PainelCalendar from './PainelCalendar';
import TaskList from './TaskList';
import api from '../axiosConfig';

const Inicio = () => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    // Carregar as tarefas do backend
    const fetchTasks = async () => {
      try {
        const token = localStorage.getItem('authToken');  // Obtenha o token armazenado
        const response = await api.get('/tasks');
        setTasks(response.data);
      } catch (error) {
        console.error("Erro ao carregar tarefas:", error);
      }
    };
    
    fetchTasks();
  }, []);

  const handleAddTask = async (newTask) => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.post('/tasks', newTask);
      setTasks([...tasks, response.data]);
    } catch (error) {
      console.error("Erro ao adicionar tarefa:", error);
    }
  };
  
  // Similar para handleUpdateTask e handleDeleteTask
  
  const handleUpdateTask = async (updatedTask) => {
    try {
      const token = localStorage.getItem('authToken');
      const taskToUpdate = {
        description: updatedTask.description,
        startDate: updatedTask.startDate,
        endDate: updatedTask.endDate,
      };
  
      await api.put(`/tasks/${updatedTask._id}`, taskToUpdate);
  
      setTasks(tasks.map(task => (task._id === updatedTask._id ? { ...task, ...taskToUpdate } : task)));
    } catch (error) {
      console.error("Erro ao atualizar tarefa:", error);
    }
  };
  
  
  const handleDeleteTask = async (taskId) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!taskId) {
        throw new Error('ID da tarefa não está definido.');
      }
      
      await api.delete(`/tasks/${taskId}`);
      setTasks(tasks.filter(task => task._id !== taskId));
    } catch (error) {
      console.error("Erro ao apagar tarefa:", error);
    }
  };
  

  return (
    <div className="p-8 flex space-x-8 h-screen">
      <div className="w-3/4">
        <PainelCalendar
          tasks={tasks}
          onAddTask={handleAddTask}
          onUpdateTask={handleUpdateTask}
          onDeleteTask={handleDeleteTask}
        />
      </div>
      <div className="w-1/4">
        <TaskList tasks={tasks} />
      </div>
    </div>
  );
};

export default Inicio;
